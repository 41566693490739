import React, { useState } from 'react';

import CardAnnualProjection from '~/pages/Simulation/Results/Amortization/Components/CardAnnualProjection';
import CardRangeValueAndYears from '~/pages/Simulation/Results/Amortization/Components/CardRangeValueAndYears';
import CardSimulationConditions from '~/pages/Simulation/Results/Amortization/Components/CardSimulationConditions';
import CardTotalValueFInanced from '~/pages/Simulation/Results/Amortization/Components/CardTotalValueFInanced';

import Swal from 'sweetalert2';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useServiceOrderFake } from '~/hooks/ServiceOrderFake';
import api from '~/services/api';
import { CardBoxContainer } from './styles';

const SimulationBank: React.FC = () => {
  const [osOnLoading, setOsOnLoading] = useState(false)
  const { serviceOrder: serviceOrderFake } = useServiceOrderFake();
  const { setServiceOrder } = useServiceOrder();

  async function handleSetUpdateOS() {
    try {
      setOsOnLoading(true)

      // serviceOrderFake é populado com os dados atuais da OS e alterado conforme a interação do usuário 
      const response = await api.put(
        `builders/service-orders/${serviceOrderFake.id}/`,
        {
          ...serviceOrderFake
        }
      );

      if (response.status !== 200) throw new Error('')

      Swal.fire({
        icon: 'success',
        title: 'Ordem de Serviço foi atualizada'
      })

      setServiceOrder(serviceOrderFake)

      setOsOnLoading(false)
    } catch (error) {
      setOsOnLoading(false)
      Swal.fire({
        icon: 'warning',
        title: 'Não foi possivel atualizar a Ordem de Serviço'
      })
    }
  }
  return (
    <CardBoxContainer>
      <div className="row box-details">
        <div className="col-lg-7 mb-4 mb-lg-5">
          <CardTotalValueFInanced
            // @ts-ignore
            os={serviceOrderFake}
          />
        </div>

        <div className="col-lg-5 mb-4 mb-lg-5">
          <CardSimulationConditions
            // @ts-ignore
            os={serviceOrderFake}
          />
        </div>

        <div className="col-lg-7 mb-4 mb-lg-5">          
          <CardRangeValueAndYears
            // @ts-ignore
            os={serviceOrderFake}
          />
        </div>

        <div className="col-lg-5 mb-4 mb-lg-5">
          <CardAnnualProjection
            // @ts-ignore
            os={serviceOrderFake}
          />
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-end mb-5">
        <button
          onClick={handleSetUpdateOS}
          type="button"
          className='rounded-[0.937rem] bg-emerald-500 
            text-white font-semibold 
            flex flex-row
            items-center justify-center
            w-[10rem] h-[3rem]
            hover:bg-emerald-600
            transition-colors
            duration-[0.3s]
            disabled:cursor-not-allowed
            disabled:opacity-80
          '
        >
          {
            osOnLoading && (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )
          }

          Atualizar
        </button>
      </div>
    </CardBoxContainer>
  );
}

export default SimulationBank;
