import { IMBudgetParameter } from '~/models/BudgetParameter';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { IParameter, IStep } from '~/pages/BuildYourHouse/Partials/Steps/Interior/types';
import budgetCalculation from './budgetCalculation';


export const SERVICER_ORDER_ONESEVEN = 1.7

export const getCreditValue = (serviceOrder: IMServiceOrder): number => {
  return (
    (serviceOrder.simulacao?.vlrFinanciado ?? 0) +
    (serviceOrder.simulacao?.vlrFgts ?? 0) +
    (serviceOrder.simulacao?.vlrEntrada ?? 0) +
    (serviceOrder.simulacao?.vlrSubsidio ?? 0)
  );
};

export const getBudget = (serviceOrder: IMServiceOrder): number => {

  console.table({
    creditValue: getCreditValue(serviceOrder),
    creditValueReadlable: Intl.NumberFormat('pt-BR', {currency: 'BRL',style: 'currency'}).format(getCreditValue(serviceOrder)),
    creditValueAvailable: getCreditValue(serviceOrder) -
    (serviceOrder.finalidade_id === 1
      ? serviceOrder.simulacao?.vlrTerreno ?? 0
      : 0),
      finalidade: `${serviceOrder.finalidade_id} - ${serviceOrder.finalidade?.finalidade}`,
      terrainAmount: serviceOrder.simulacao?.vlrTerreno ?? 0,
      terrainAmountReadlable: Intl.NumberFormat('pt-BR', {currency: 'BRL',style: 'currency'}).format(parseFloat((serviceOrder.simulacao?.vlrTerreno ?? 0).toFixed(2))),
  })

  return (
    getCreditValue(serviceOrder) -
    (serviceOrder.finalidade_id === 1
      ? serviceOrder.simulacao?.vlrTerreno ?? 0
      : 0)
  );
};


export const getMarketValue = (serviceOrder: IMServiceOrder): number => {
  return (serviceOrder.simulacao?.vlrFinanciado ?? 0) * 1.55;
};

export const getTotalEnvironmetValue = (
  orc_ambientes: IMBudgetRoom[] | IStep[] = []
): number => {
  return (
    (orc_ambientes as any[]).reduce((previousValue, currentValue) => {
      return previousValue + (currentValue?.vlrFinal ?? 0);
    }, 0) ?? 0
  );
};

/**
 * Will check if the key's on param are valid key to orc_parametros
 * @param key 
 * @returns 
 */
function handlerChecksAvailableParameter(key: string = ''){
    try {
      const AVAILABLE_PARAMETERS = ["acabamento", "terreno","estilo_construcao", "eletrica", "hidraulica"]

      return AVAILABLE_PARAMETERS.includes(key);
    } catch (error) {
      return false;
    }
  }

export const getAccumulatedTotal = (orc_parametros: IParameter | IMBudgetParameter | undefined = undefined, orc_ambientes: IMBudgetRoom[] | IStep[] = []): number => {
  const parameters: IParameter[] = [];  

  Object.keys(orc_parametros || {})
    .filter( 
        // @ts-ignore
        key => typeof orc_parametros?.[key] === 'object'
        &&
        handlerChecksAvailableParameter(key)
      )
    .forEach((p) => {
        // @ts-ignore 
        parameters.push({margem: orc_parametros[p]?.margem || 0});
    });

  console.log('THE getAccumulatedTotal WAS CALLED')

  let accumulatedTotal = budgetCalculation({
    environments: orc_ambientes || [],
    // @ts-ignore
    parameters
  })

  console.table({
    orc_parametros,
    orc_ambientes,
    parameters,
    accumulatedTotal
  })

  return accumulatedTotal
};

export const getFootage = (serviceOrder: IMServiceOrder): number => {
  return (
    serviceOrder.orc_ambientes
      ?.map((elem) => elem.metragem)
      .reduce((total, atual) => total + atual, 0) ?? 0
  );
};

export const getWeightedAverage = (serviceOrder: IMServiceOrder): number => {
  return (
    (serviceOrder.orc_ambientes
      ?.map((elem) => elem.metragem * elem.vlrLucro)
      .reduce((total, atual) => total + atual, 0) ?? 0) /
    getFootage(serviceOrder)
  );
};

export const getSuggestedFootage = (serviceOrder: IMServiceOrder): number => {
  console.table({
    vlrLucro: serviceOrder.orc_ambientes?.map((elem) => elem.vlrLucro),
    vlrLucroWithMargin: serviceOrder.orc_ambientes?.map((elem) => elem.metragem * elem.vlrLucro),
    totalMargin: getFootage(serviceOrder),
    budget: getBudget(serviceOrder),
    weightedAverage: getWeightedAverage(serviceOrder),
    meters: getBudget(serviceOrder) / getWeightedAverage(serviceOrder)
  })

  return getBudget(serviceOrder) / getWeightedAverage(serviceOrder);
};

/**
 * Get total amount of price from all environments, eg. Quarto, Banheiro, Closets...
 * @param serviceOrder 
 * @param bdi 
 * @returns 
 */
export const calculateEnvironmentsValues = (
  serviceOrder: IMServiceOrder,
  bdi: number
): IMBudgetRoom[] => {
  console.log(`calculateEnvironmentsValues WAS CALLED WITH`, {bdi})
  const environments = serviceOrder.orc_ambientes?.map((elem) => {
    console.log(`calculateEnvironmentsValues WAS CALLED WITH ENVIRONMENT`, {elem})

    const {stateValue,valueFinal, valueFinishing, valueProfit} = getAmountStatement(
      serviceOrder?.terreno?.estado?.valor, 
      elem?.ambiente_tamanho?.ambiente?.margem || 0,
      elem?.metragem, 
      serviceOrder?.orc_parametros?.acabamento?.margem,
      bdi
    )

    return {
      ...elem,
      vlrEstado: stateValue,
      vlrAcabamento: valueFinishing,
      vlrLucro: valueProfit,
      vlrFinal: valueFinal,
    };
  });

  return environments ?? [];
};

/**
 * Set a new amount of price to option
 * @param stateValue Value of state eg: Rio de Janeiro R$ 2.700,00
 * @param environmentMargin Margin of environment, eg: Quarto 0.8
 * @param environmentSize Size of environment, eg: Quarto 32m²
 * @param finishingMargin Margin from style of house, eg: Ceramic 2.5
 * @param bdi Value of profit from builder, eg: 20%
 * @returns 
 */
export function getAmountStatement(stateValue = 0, environmentMargin = 0, environmentSize = 0, finishingMargin = 1,  bdi = 0) {
  try {
    const STATE_VALUE = stateValue * environmentMargin;
    const VALUE_FINISHING = STATE_VALUE * (finishingMargin || 1);
    const VALUE_PROFIT = VALUE_FINISHING * ((bdi / 100) + 1);
    const VALUE_FINAL = VALUE_PROFIT * environmentSize;


    console.log(`THE getAmountStatement WAS CALLED`, 
      {stateValue, environmentMargin, finishingMargin, bdi}, 
      {
        stateValue: STATE_VALUE,
        valueFinishing: VALUE_FINISHING,
        valueProfit: VALUE_PROFIT,
        valueFinal: VALUE_FINAL,
        environmentSize
      }
    )

    return {
      stateValue: STATE_VALUE,
      valueFinishing: VALUE_FINISHING,
      valueProfit: VALUE_PROFIT,
      valueFinal: VALUE_FINAL,
    }
  } catch (error) {
    return {
      stateValue: 0,
      valueFinishing: 0,
      valueProfit: 0,
      valueFinal: 0,
    }
  }
}

/**
 * Get the amount of the property after running
 * @param [vlrEntrada=0] The amount of entry
 * @param [vlrFinanciado=0] The amount financed 
 * @param [byPass=1.5] The bypass
 */

export function getAmountAfterRunning(vlrFinanciado= 0,vlrEntrada= 0, byPass = 1.5) {
  // return ((serviceOrder.simulacao?.vlrFinanciado ?? 0)
  //         + (serviceOrder.simulacao.vlrEntrada ?? 0))
  //         * byPass
  return ((vlrFinanciado)
          + vlrEntrada)
          * byPass
}
