import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMFileType } from '~/models/FileTypes';

import { IMServiceOrderFile } from '~/models/ServiceOrderFile';

import api from '~/services/api';
import CustomersDocuments from './Partials/CustomersDocuments';
import ExtraDocuments from './Partials/ExtraDocuments';
import ModalConclude from './Partials/ModalConclude';
import ProcessOpening from './Partials/ProcessOpening';
import SellersDocuments from './Partials/SellersDocuments';
import { Container, SendAnalysis } from './styles';

export interface IFiles extends IMFileType {
  files?: IMServiceOrderFile[];
}

const WorkDocuments: React.FC = () => {
  const { serviceOrder, participants } = useServiceOrder();
  const [showConclude, setShowConclude] = useState(false);
  const [lackFiles, setLackFiles] = useState(false)

  // AUX Variables
  const BANK = serviceOrder.simulacao?.amortizacao?.amortizacao.toLocaleLowerCase() || ''
  const HAS_CAIXA_BANK = useMemo(() => BANK.includes('caixa'), [])
  const FILE_TYPE_TO_CAIXA_BANK = "Autorizacao pesquisa cadastral";

  const handleOpenConclude = useCallback(() => {
    setShowConclude(true);
  }, []);

  const getLackFiles = useCallback(() => {
    const PARTICIPANTS: any = {}

    participants.map(p => {
      if (p?.type === 'spouse') {
        PARTICIPANTS['conjuge_id'] = p.id
      } else if (p?.type === 'participant') {
        PARTICIPANTS['participante_id'] = p.id
      }
    });

    api
      .get<IMFileType[]>(
        `builders/file-types/${serviceOrder.id}`,
        {
          params: {
            grupo: 1,
            ...PARTICIPANTS,
          },
        }
      )
      .then((response) => {
        response.data.forEach(d => {

          const TOTAL_FILES = d.files?.length || 0


          if (TOTAL_FILES <= 0 && (serviceOrder.status.id === 3 || serviceOrder.status.id === 5)) {
            if ((d.nomenclatura === FILE_TYPE_TO_CAIXA_BANK && HAS_CAIXA_BANK) || (d.nomenclatura !== FILE_TYPE_TO_CAIXA_BANK && !HAS_CAIXA_BANK)) {
              setLackFiles(true);
            } else {
              setLackFiles(false);
            }
          } else {
            setLackFiles(false);
          }

          // if(TOTAL_FILES <= 0 && d.nomenclatura === FILE_TYPE_TO_CAIXA_BANK && HAS_CAIXA_BANK && (serviceOrder.status.id === 3 || serviceOrder.status.id === 5)) {
          //   setLackFiles(true);
          // } else if(TOTAL_FILES <= 0 && d.nomenclatura !== FILE_TYPE_TO_CAIXA_BANK  && !HAS_CAIXA_BANK && (serviceOrder.status.id === 3 || serviceOrder.status.id === 5)) {
          // } else {
          //   setLackFiles(false);
          // }            
        })
      });


  }, [lackFiles, participants])

  useEffect(() => {
    getLackFiles()
  }, [participants]) 

  return (
    <Container>
      {
        <CustomersDocuments />
      }

      {serviceOrder.status_id >= 9 && 
        <>
          {serviceOrder.finalidade_id === 1 && <SellersDocuments />}
          <ProcessOpening />
        </>
      }

      {serviceOrder.status_id > 6 && <ExtraDocuments />}
      
      <ModalConclude
        show={showConclude}
        onHide={() => setShowConclude(false)}
      />
      
      <SendAnalysis className='flex flex-row items-center gap-4'>
        <span
          role='button'
          onClick={getLackFiles}
          data-showme={true}
          className='data-[showme=true]:flex hidden text-gray-400 cursor-pointer font-semibold'>
          Atualizar lista
        </span>

        
        <button
          type="button"
          disabled={lackFiles}
          className="sendAnalisys disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
          onClick={() => handleOpenConclude()}
        >
          <span data-showme={lackFiles} className='data-[showme=false]:hidden flex'> Enviar para análise </span>
          <span data-showme={lackFiles} className='data-[showme=false]:flex hidden'> Ainda faltam arquivos </span>
        </button>         

        {/* {
          allowComplementaryForm && (serviceOrder.status_id === 2  || serviceOrder.status_id  === 3 || serviceOrder.status_id  === 5)  && (
            <button
              type="button"
              className="sendAnalisys disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
              onClick={() => setAllowComplementaryForm(d => !d)}
            >
              <span data-show={allowComplementaryForm} className='hidden data-[show=true]:flex'>Fechar Formulário</span>
            </button>
          )
        } */}
      </SendAnalysis>        
    </Container>
  );
};

export default WorkDocuments;
