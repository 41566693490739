import * as Zod from 'zod'

export const SCFormCT = Zod.object({
    approval_property: Zod.number({
        coerce: true,
        required_error: 'Este campo precisa ser marcado',
        description: 'Obra já possui projeto aprovado em prefeitura?',
        invalid_type_error: 'Este campo precisa ser marcado'
    }),
    terrain_or_build: Zod.number({
        coerce: true,
        required_error: 'Este campo precisa ser preenchido',
        description: 'Terreno de rua ou condomínio?',
        invalid_type_error: 'Este campo precisa ser marcado'
    }),
    has_rgi: Zod.number({
        coerce: true,
        required_error: 'Este campo precisa ser preenchido',
        description: 'Possui RGI em seu nome ?',
        invalid_type_error: 'Este campo precisa ser marcado'
    }),
    terrain_amount: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor do terreno',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    // .refine((value => {
    //     return parseInt(value,10) >= 1500000.00
    // }), 'Informe um valor igual ou acima de R$ 150.000,00'),

    land_outstanding_balance: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Saldo devedor do terreno',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    amount_build_estimated: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor total de obra (estimado)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    property_value_estimated: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor do imóvel pronto para compra e venda (estimado)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    spent_of_project: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Quanto já gastou na obra',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    how_much_resources: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Quanto ainda possui em recursos próprios (fora o que já foi gasto na obra)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    formal_monthly_income: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Renda mensal formal',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    unformal_monthly_income: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: ' Renda mensal Informal',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    city_of_terrain: Zod.string({        
        required_error: 'Este campo precisa ser preenchido',
        description: 'Cidade do terreno'
    }),
    build: Zod.string({        
        required_error: 'Este campo precisa ser preenchido',
        description: 'Condomínio'
    }),
    address_terrain: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Endereço completo do terreno com CEP'
    }),
})

export const SCFormAC = Zod.object({
    terrain_amount: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor do terreno',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    // }).refine((value => {
    //     return parseInt(value,10) >= 400000.00
    // }), 'Informe um valor igual ou acima de R$ 400.000,00'),
    land_outstanding_balance: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Saldo devedor do terreno',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    amount_build_estimated: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor total de obra (estimado)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    property_value_estimated: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Valor do imóvel pronto para compra e venda (estimado)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    how_much_resources: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Quanto ainda possui em recursos próprios (fora o que já foi gasto na obra)',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    formal_monthly_income: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Renda mensal formal',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    unformal_monthly_income: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: ' Renda mensal Informal',
        invalid_type_error: 'Informe  valor monetário válido'
    }),
    city_of_terrain: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Cidade do terreno'
    }),
    // .refine(d => {
    //     console.log(d, d.trim(), d.length, !d ? true : false)
    //     return !d ? false : true
    // }, "Preencha este campo"),
    name_of_build: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Qual o nome do Condomínio'
    }),
    // .refine(d => {
    //     console.log(d, d.trim(), d.length, !d ? true : false)
    //     return !d ? false : true
    // }, "Preencha este campo"),
    address_terrain: Zod.string({
        required_error: 'Este campo precisa ser preenchido',
        description: 'Endereço completo do terreno com CEP'
    }),
    // .refine(d => {
    //     console.log(d, d.trim(), d.length, !d ? true : false)
    //     return !d ? false : true
    // }, "Preencha este campo"),
})