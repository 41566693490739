import { FormHandles } from '@unform/core';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { formatToFloat } from '~/utils/format';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { IOption } from '~/components/Select';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMParticipant } from '~/models/Participant';
import api from '~/services/api';

import Loading from '~/components/Loading';
import { IMSpouse } from '~/models/Spouse';
import CompleteDataBox from '../CompleteDataBox';
import FormEdit from './FormEdit';

interface IEditParticipant {
  participant?: IMParticipant;
  color?: string;
}
interface IParams {
  osId: string;
}

interface IFormData {
  type: string;
  id: string;
  os_id: string;
  nome: string;
  cpf: string;
  cnpj: string;
  dt_nascimento: string;
  data_expedicao: string;
  rg: string;
  vlrRenda: string;
  fgts: string;
  vlrFgts: string;
  numPis: string;
  profissao: string;
  nvEscolar_id: string;
  email: string;
  celular: string;
  casado: string;
  spouse: string;
  client: string;
}

const EditParticipant: React.FC<IEditParticipant> = ({
  participant,
  color,
}) => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [nameLengthError, setNameLengthError] = useState('');
  const [cpfError, setCpfError] = useState('');
  const [hasFGTS, setHasFGTS] = useState(false);
  const [hasMarried, setHasMarried] = useState(!!participant?.casado);
  const [show] = useState(hasFGTS);
  const [fisrtLoad, setFisrtLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleValidateName = useCallback((value: string) => {
    setNameLengthError('');
    value.trim().split(' ').length > 1
      ? setNameLengthError('')
      : setNameLengthError('Informe o nome completo');
  }, []);

  // FIXME - eslint-disable-next-line consistent-return
  const handleValidateCPFInput = useCallback((value: string) => {
    setCpfError('');
    if (value.replace(/\D/g, '').length < 11) return false;
    cpfValidator.isValid(value.replace(/\D/g, ''))
      ? setCpfError('')
      : setCpfError('CPF inválido');
  }, []);

  const handleChangeHasMarried = useCallback((option: IOption) => {
    setHasMarried(option.value === 'Sim');
  }, []);

  const handleChangeHasFGTS = useCallback((option) => {
    setHasFGTS(option.value === 'Sim');
  }, []);

  useEffect(() => {
    participant?.vlrFgts !== undefined
      ? setHasFGTS(Number(participant?.vlrFgts) !== 0)
      : setHasFGTS(false);

    if (participant?.casado && Number(participant.casado) === 1) {
      setHasMarried(true);
    } else {
      setHasMarried(false);
    }

    formRef.current?.setErrors({});
  }, [participant]);

  useEffect(() => {
    setFisrtLoad(true);
  }, []);

  useEffect(() => {
    if (fisrtLoad) {
      let { conjuge } = serviceOrder;
      if (
        serviceOrder.cliente.casado
        && (!conjuge || Object.keys(conjuge).length === 0)
      ) {
        conjuge = {
          nome: 'Conjuge',
        };
      }

      if (conjuge && !conjuge.nome) {
        conjuge.nome = 'Conjuge';
      }

      setServiceOrder({
        ...serviceOrder,
        conjuge,
      });
      setFisrtLoad(false);
    }
  }, [fisrtLoad, serviceOrder, setServiceOrder]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome é obrigatório'),
          cpf: Yup.string().required('O CPF é obrigatório'),
          email: Yup.string().required('O email é obrigatório'),
          vlrRenda: Yup.string().required('O valor da renda é obrigatório'),
          celular: Yup.string()
            .required('O numero de celular é obrigatório')
            .min(14, 'O numero de celular completo é obrigatório')
            .nullable(),
          profissao: Yup.string().required('A profissão é obrigatório'),
          nvEscolar_id: Yup.string().required(
            'O nível de escolaridade é obrigatório'
          ),
          vlrFgts: Yup.string().when('$fgts_value', {
            is: (fgtsValueCheck: boolean) => fgtsValueCheck,
            then: Yup.string().required('O valor de FGTS é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            vlrFgts: hasFGTS,
          },
        });

        if (nameLengthError.length === 0 && cpfError.length === 0) {
          setLoading(true);
          const {
            nome,
            cpf,
            email,
            celular,
            profissao,
            casado,
            fgts,
            nvEscolar_id,
            numPis,
          } = data;

          let vlrRenda = 0;
          if (data.vlrRenda) {
            vlrRenda = parseFloat(String(data.vlrRenda));
            if (Number.isNaN(vlrRenda)) {
              vlrRenda = formatToFloat(String(data.vlrRenda));
            }
          }

          let vlrFgts = 0;
          if (data.vlrFgts) {
            vlrFgts = parseFloat(String(data.vlrFgts));
            if (Number.isNaN(vlrFgts)) {
              vlrFgts = formatToFloat(String(data.vlrFgts));
            }
          }

          const formData = {
            os_id: params.osId,
            nome,
            cpf,
            email,
            celular,
            vlrRenda,
            profissao,
            casado,
            fgts,
            vlrFgts,
            nvEscolar_id,
            numPis,
          };

          if (participant?.type === 'client') {
            const clientFormData = {
              os_id: serviceOrder.id,
              nome,
              email,
              documento: cpf,
              vlrRenda,
              vlrFgts,
              profissao,
              numPis,
              casado,
              nvEscolar_id,
            };
            await api.put(
              `${process.env.REACT_APP_PREFIX_ROUTE}/customers/${participant?.id}`,
              clientFormData
            );

            setServiceOrder({
              ...serviceOrder,
              cliente: {
                ...serviceOrder.cliente,
                nome,
                email,
                documento: cpf,
                vlrRenda,
                vlrFgts,
                profissao,
                numPis,
                casado: casado === '1',
                nvEscolar_id: parseInt(nvEscolar_id, 10),
              },
              conjuge: casado === '1' ? serviceOrder.conjuge : ({} as IMSpouse),
            });
          } else if (participant?.type === 'spouse') {
            participant?.id
              ? await api.put(
                `/builders/service-orders/spouses/${participant?.id}`,
                formData
              )
              : await api.post(
                `/builders/service-orders/spouses/${serviceOrder.id}`,
                formData
              );
            setServiceOrder({
              ...serviceOrder,
              conjuge: {
                ...serviceOrder.conjuge,
                nome,
                cpf,
                email,
                celular,
                vlrRenda,
                profissao,
                vlrFgts,
                nvEscolar_id: parseInt(nvEscolar_id, 10),
                numPis,
              },
            });
          } else {
            await api.put(
              `/builders/participants/${participant?.id}`,
              formData
            );
            setServiceOrder({
              ...serviceOrder,
              participantes: serviceOrder?.participantes?.map((item) => {
                if (item.id === participant?.id) {
                  return {
                    ...item,
                    nome,
                    cpf,
                    email,
                    celular,
                    vlrRenda,
                    profissao,
                    casado: casado === '1',
                    nvEscolar_id: parseInt(nvEscolar_id, 10),
                    fgts,
                    vlrFgts,
                    numPis,
                  } as any;
                }
                return item;
              }),
            });
          }

          setLoading(false);
          Toast.fire({
            icon: 'success',
            title: 'Dados atualizados com sucesso!',
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire({
            icon: 'error',
            title:
              'Falha ao guardar os dados do participante, tente novamente!',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      formRef,
      nameLengthError,
      cpfError,
      hasFGTS,
      participant,
      params.osId,
      serviceOrder,
      setServiceOrder,
    ]
  );

  return (
    <div>
      <FormEdit
        participant={participant}
        color={color}
        nameLengthError={nameLengthError}
        cpfError={cpfError}
        hasMarried={hasMarried}
        hasFGTS={hasFGTS}
        formRef={formRef}
        onHandleSubimt={handleSubmit as any}
        onHandleValidateName={handleValidateName}
        onHandleValidateCPFInput={handleValidateCPFInput}
        onHandleChangeHasMarried={handleChangeHasMarried}
        onHandleChangeHasFGTS={handleChangeHasFGTS}
      />
      {show && (
        <div className="row mt-2">
          <CompleteDataBox />
        </div>
      )}
      <Loading active={loading} />
    </div>
  );
};

export default EditParticipant;
